<template>
	<MhRouterView class="HomeView view" :isContentReady="isContentReady">

		<div class="view__inner">

			<!--
			<br /><strong>HomeView.vue</strong><hr /><br />
			<pre>{{ post }}</pre>
			-->

			<InteriorHome class="view__inner"
				:guidePosts="guidePosts"
			></InteriorHome>

			<!--
			<br/>
			-->

			<!--
			<pre data-name="pages">{{pages}}</pre>
			<pre name="attachments">{{app.attachments}}</pre>
			-->

		</div>

	</MhRouterView>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import currentEnv from '@/helper/getCurrentEnv'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'
	import InteriorHome from '@/INTERIOR/InteriorHome.vue'

	export default {
		name: 'HomeView',
		components: {
			MhRouterView,
			InteriorHome,
		},
		mixins: [ restHandler ],
		directives: {},
		props: {},
		data(){
			return {
				isContentReady: false,
				post: false,
				guidePosts: false,
				currentEnv: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchAll( finalCallback ){
				let thingsToLoad = 2

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad, finalCallback)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				// fetch frontpage
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/frontpage',
					params : {},
					callback : (response) => {
						const post = this._.get( response, 'data.result' )

						this.post = post

						runCallback()
					},
				})
				// fetch guides
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/posts?postType=guide_post',
					params : {
						metaKey : 'isVisibleOnHome',
						metaValue : 1,
					},
					callback : (response) => {
						const guidePosts = this._.get( response, 'data.result' )

						this.guidePosts = guidePosts

						runCallback()
					},
				})
			},
		},
		mounted() {
			this.currentEnv = currentEnv

			this.fetchAll(()=>{
				console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isContentReady = true
			})
			/*
			this.isContentReady = true
			*/
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.HomeView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
